import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.pos>=0)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        id: "flag",
        style: _normalizeStyle(_ctx.flagStyle)
      }, null, 4))
    : _createCommentVNode("", true)
}