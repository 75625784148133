
import { Options, Vue } from 'vue-class-component';
import MapQuiz from '@/components/MapQuiz.vue'; // @ is an alias to /src
import CssStripeFlag from '@/components/CssStripeFlag.vue'; // @ is an alias to /src
import info from '@/info/nations.json';

@Options({
  components: {
    MapQuiz,
    CssStripeFlag
  },
})
export default class UsNations extends Vue {
  answer:any = {};
  info:any=info;

  updateAnswer(newAnswer:any){
    this.answer = newAnswer;
  }
}
