import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0916785c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { style: {"font-size":"2rem","line-height":"2em"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_location_ruby = _resolveComponent("location-ruby")!
  const _component_map_quiz = _resolveComponent("map-quiz")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_map_quiz, {
      id: _ctx.info.id,
      geojsons: {d3:'/geo/jp_kuni.d3.geojson'/*,leaflet:'/geo/jp_prefectures.leaflet.geojson'*/},
      info: _ctx.info,
      overwrapText: _ctx.overwrapText,
      onUpdateAnswer: _ctx.updateAnswer,
      credits: "\n      <a href='https://booth.pm/ja/items/3053727' target='_blank'>郡地図 Ver 1.1</a>（日本。容量削減のため加工）\n       | Made with <a href='https://www.naturalearthdata.com/' target='_blank'>Natural Earth</a>.(周辺国・地域)",
      maxScale: 40
    }, {
      default: _withCtx(() => [
        (_ctx.answer)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.answer.group), 1),
              _createElementVNode("h1", null, [
                _createVNode(_component_location_ruby, {
                  name: _ctx.answer.kuni,
                  yomi: _ctx.answer.yomi
                }, null, 8, ["name", "yomi"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["id", "geojsons", "info", "overwrapText", "onUpdateAnswer"])
  ]))
}