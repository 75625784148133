
import { Options, Vue } from 'vue-class-component';
import MapQuiz from '@/components/MapQuiz.vue'; // @ is an alias to /src
import LocationRuby from '@/components/LocationRuby.vue'; // @ is an alias to /src
import info from '@/info/jp_kuni.json';

@Options({
  components: {
    MapQuiz,
    LocationRuby,
  },
})
export default class JpKuni extends Vue {
  answer:any = {};
  overwrapText = "";
  info:any=info;
  
  updateAnswer(newAnswer:any){
    this.answer = newAnswer;
    this.overwrapText = newAnswer.name;
  }
}
