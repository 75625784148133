
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    flagSet:String,
    codes:Array,
    code:String,
  },
  components: {
  },
})

export default class CssStripeFlag extends Vue {
  /*
  xx:number;
  yy:number;
  */
  ww=240;
  hh=160;
  flagSet!:string;
  codes!:string[];
  code!:string;
  
  created(){
    const img1 = new Image();
    img1.src=`/img/flag/${this.flagSet}.png`;
  }
  get pos(){
    return this.codes.indexOf(this.code);
  }
  
  get flagStyle(){
    const pos = this.pos;
    const xx = (pos==-1)?0:pos%10;
    const yy = (pos==-1)?0:Math.floor(pos/10);
    return {
      "background": `url("/img/flag/${this.flagSet}.png")`,
      "background-size" :this.ww/2*10+"px",
      "background-position-x": (-xx*this.ww/2)+"px",
      "background-position-y": (-yy*this.hh/2)+"px",
      "width":this.ww/2+"px",
      "height":this.hh/2+"px",
    }
  }
}


