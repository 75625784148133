
import { Options, Vue } from 'vue-class-component';
import MapWrapper from '@/components/MapWrapper.vue'; // @ is an alias to /src

@Options({
  components: {
    MapWrapper
  },
})
export default class ChomeiTest extends Vue {
  mapJSON = "/geo/h27ka20.geojson";
}
