import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-409d7361"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_css_stripe_flag = _resolveComponent("css-stripe-flag")!
  const _component_map_quiz = _resolveComponent("map-quiz")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_map_quiz, {
      id: _ctx.info.id,
      geojsons: {d3:'/geo/nations.geojson'},
      info: _ctx.info,
      onUpdateAnswer: _ctx.updateAnswer,
      credits: "<a href='https://www.naturalearthdata.com/' target='_blank'>Natural Earth</a>"
    }, {
      default: _withCtx(() => [
        _createElementVNode("h1", null, _toDisplayString(_ctx.answer.name || ""), 1),
        _createVNode(_component_css_stripe_flag, {
          flagSet: _ctx.info.id,
          code: _ctx.answer.id,
          codes: _ctx.info.items.map(item=>item.id)
        }, null, 8, ["flagSet", "code", "codes"]),
        _createElementVNode("p", null, "首都: " + _toDisplayString(_ctx.answer.capital), 1)
      ]),
      _: 1
    }, 8, ["id", "geojsons", "info", "onUpdateAnswer"])
  ]))
}